/**
 * Module for showing Inventory Details.
 * @module src/InventoryRequest/InventoryRequestDetail
 */
/* eslint-disable array-callback-return */
/* eslint-disable no-lonely-if */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Button from '@salesforce/design-system-react/components/button';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import CancelInventory from '../InventoryRequestCancel';
// eslint-disable-next-line import/no-named-as-default
import InventoryRequestDetailHeader from './InventoryRequestDetailHeader';
import InventoryRequestDetailCatalog from './InventoryRequestDetailCatalog';
import CANCEL_INVENTORY from '../../../graphql/mutations/cancelInventory';
import SUBMIT_INVENTORY_REQUEST from '../../../graphql/mutations/submitInventoryRequest';
import RESUBMIT_ORDER_DETAILS from '../../../graphql/mutations/resubmitOrderDetails';
import { GET_INVENTORY_REQUEST_DETAILS } from '../../../graphql/getInventoryDetails';
import { GET_ORDER_DETAILS } from '../../../graphql/getOrderDetails';
import DetailsHeader from './DetailsHeader';
import LineItems from './LineItems';
import Comments from './Comments';
import './index.scss';
import InventoryRequest from '../InventoryRequest';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';
import { getUserInfo, setUserInfo } from '../../../store/ducks/userInfo';
import SnackBar from '../../Shared/SnackBar';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import {
  NonSalesRep,
  NOTIFY_TYPE,
  OPS_ACTION_TYPE,
  PERSONA_CONDITION,
} from '../../../util/constants';
import { getUserId } from '../../../store/ducks/userId';
import { GET_USER_INFO } from '../../../graphql/getUserInfoData';
import CREATE_COMMENT from '../../../graphql/mutations/createComment';
import { GET_WORKFLOW_PERMISSIONS } from '../../../graphql/workflowPermissions';
import CREATE_UPDATE_INVENTORY_FAVORITE from '../../../graphql/mutations/createUpdateInventoryFavorites';
import InventoryFavoriteSaveModal from './FavoriteSaveConfirmationModal';
import { warningValidation, getUTCDate, getUTCDateTime } from '../../../util/utilityFunctions';
import { GET_DETAILS_VIEW_CONFIGURATIONS } from '../../../graphql/getDetailsViewConfigurations';
import CREATE_UPDATE_USAGE from '../../../graphql/mutations/createdUpdateUsage';
import inventoryRequest from '../../../util/FormJson/inventoryRequest';

interface LineItems {
  productId: string;
  quantity: string;
  productNumber: string;
  workflowAction?: string;
  usageId?: string;
  usageExternalId?: string;
}
interface Comment {
  id?: string;
  value: string;
  comment?: string;
  type?: string;
  commentType?: string;
  postTime: string;
  user?: string;
}
export interface Props extends RouteComponentProps {
  children?: FC;
  eventInventoryData?: any;
  eventInventoryExternalId?: string;
  hasEventInventory?: boolean;
  tabConfig?: any;
}
type ReturnParams = {
  externalId: string;
  type: string;
  id: string;
  isEventInventoryFlow: string | undefined;
  caseExternalId: string | undefined;
};

const InventoryRequestDetail: FC<Props> = ({
  eventInventoryData = null,
  location,
  eventInventoryExternalId,
  history,
  hasEventInventory,
  tabConfig,
}) => {
  const { state } = location;
  const useParam = useParams<ReturnParams>();
  const parameters = state || useParam;
  const { externalId, type, id, isEventInventoryFlow, caseExternalId } = parameters;
  useQuery(GET_WORKFLOW_PERMISSIONS);
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const [loader, setLoader] = useState(false);
  const [checkStatusActive, setCheckStatusActive] = useState(false);
  const userId = useSelector(getUserId);
  const [userComment, setUserComment] = useState<any[]>();
  const [updatedLineItems, setLineItems] = useState<LineItems[]>();
  const [shipMethod, setShipMethod] = useState('');
  const { open, notification, openSnackBar } = useSnackBar();
  // const { externalId, id, isEventInventoryFlow, type, caseExternalId } = state;
  const [openInventoryCancelModal, setOpenInventoryCancelModal] = useState(false);
  const [createComment, { data: createCommentData }] = useMutation(CREATE_COMMENT);
  // const [submitInventoryError, setSubmitInventoryError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isClone, setIsClone] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [FavName, setFavName] = useState('');
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);
  const [reprocessQueue, setReprocessQueue] = useState(false);
  const [selectedTabIndex, changeSelectedTab] = useState(0);
  const [createUpdateUsage] = useMutation(CREATE_UPDATE_USAGE);
  const [retryCount, setRetryCount] = useState(0);

  const MAX_RETRY_COUNT = 2;
  const RETRY_INTERVAL = 10000;

  const [getFormConfigurations, { data: formdata }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  const { data } = useQuery(GET_USER_INFO, {
    fetchPolicy: 'no-cache',
    variables: { id: userId },
  });
  const { data: getDetailsViewConfigurations } = useQuery(GET_DETAILS_VIEW_CONFIGURATIONS);
  const inventoryConfig =
    getDetailsViewConfigurations?.getDetailsViewConfigurations?.inventoryRequest;

  useEffect(() => {
    getFormConfigurations();
  }, [getFormConfigurations]);

  const {
    data: getOrderDetails,
    refetch: refetchProcessedEventDetails,
    loading: processedLoading,
  } = useQuery(GET_ORDER_DETAILS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: checkStatusActive,
    variables: {
      id: externalId,
      orderType: 'INVENTORY_REQUEST',
    },
  });
  const getCommentDate = (): string => {
    const finalDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    const utcDate = getUTCDate(finalDate);
    return utcDate;
  };

  const { data: getInventoryRequestDetails, refetch: refetchEventDetails, loading } = useQuery(
    GET_INVENTORY_REQUEST_DETAILS,
    {
      fetchPolicy: 'no-cache',
      // skip:eventInventoryData ? true : false,
      variables: {
        externalId:
          isEventInventoryFlow || hasEventInventory ? eventInventoryExternalId : externalId,
      },
    }
  );
  const [
    setCancelInventory,
    { loading: cancelInventoryLoading, data: cancelInventoryData },
  ] = useMutation(CANCEL_INVENTORY);

  const [
    setSubmitInventory,
    { loading: submitInventoryLoading, data: submitInventoryData },
  ] = useMutation(SUBMIT_INVENTORY_REQUEST);

  const [reSubmitInventory] = useMutation(RESUBMIT_ORDER_DETAILS);

  const [
    setInventoryFavorite,
    { loading: inventoryFavoriteLoading, data: inventoryFavoriteData },
  ] = useMutation(CREATE_UPDATE_INVENTORY_FAVORITE);
  const isNotSR = !!NonSalesRep?.find((rec: any) => rec?.value === userInfo?.personas);

  useEffect(() => {
    if (state) {
      // eslint-disable-next-line no-shadow
      const { caseExternalId, opsAction, externalId } = state;
      if (caseExternalId || externalId) {
        if (opsAction) {
          setReprocessQueue(true);
          if (opsAction && opsAction === OPS_ACTION_TYPE.ASSIGN) {
            setViewOnly(true);
          }
        }
      } else {
        setReprocessQueue(false);
      }
    }
  }, [history, state]);

  useEffect(() => {
    if (
      submitInventoryData &&
      submitInventoryData.submitInventoryRequest &&
      submitInventoryData.submitInventoryRequest.message === 'success'
    ) {
      if (refetchEventDetails) {
        refetchEventDetails();
      }
      if (refetchProcessedEventDetails) {
        refetchProcessedEventDetails();
      }
      const warningMsg = warningValidation(
        'Requested Delivery Date',
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        inventoryDetail?.needByDate,
        userInfo
      );
      if (warningMsg && warningMsg !== '') {
        setTimeout(() => {
          openSnackBar(NOTIFY_TYPE.WARNING, warningMsg);
        }, 2000);
      }

      setTimeout(() => {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Success: Inventory successfully submitted.');
        // history.push('/Inventory/Requests');
      }, 4000);
    }

    if (
      cancelInventoryData &&
      cancelInventoryData.cancelEvent &&
      cancelInventoryData.cancelEvent.message === 'success'
    ) {
      setTimeout(() => {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Success: Inventory successfully cancelled.');
      }, 1000);
    }

    if (inventoryFavoriteData && inventoryFavoriteData.createUpdateInventoryFavorite) {
      setTimeout(() => {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Success: Inventory favorite.');
      }, 1000);
      setTimeout(() => {
        history.push('/Inventory/Requests/Favorites');
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    submitInventoryData,
    refetchEventDetails,
    refetchProcessedEventDetails,
    cancelInventoryData,
    inventoryFavoriteData,
  ]);
  let inventoryDetail: any;
  if (type === 'Processed' || type === 'Failed') {
    inventoryDetail = getOrderDetails?.getOrderDetails;
  } else {
    if (eventInventoryData && externalId === eventInventoryExternalId) {
      inventoryDetail = eventInventoryData?.getInventoryRequestDetails;
    } else {
      if (getInventoryRequestDetails) {
        inventoryDetail = getInventoryRequestDetails?.getInventoryRequestDetails;
      }
    }
  }

  useEffect(() => {
    try {
      dispatch(setUserInfo(data ? data.getUserInfo[0] : {}));
    } catch (err) {
      // do nothing
    }
  }, [dispatch, data]);
  const refetch = async (): Promise<void> => {
    refetchEventDetails();
    refetchProcessedEventDetails();
  };
  const refetchData = async (): Promise<void> => {
    setLoader(true);
    await refetchEventDetails();
    await refetchProcessedEventDetails();
    setLoader(false);
  };
  const refetchOnAdd = (): void => {
    if (type === 'Failed') {
      setCheckStatusActive(true);
      refetchProcessedEventDetails();
    } else {
      refetch();
    }
    setCheckStatusActive(false);
  };

  const handleCancelInventoryModal = (): void => {
    setOpenInventoryCancelModal(!openInventoryCancelModal);
  };

  // cancelReason: string
  const cancelInventory = (): void => {
    const mutation = {
      sfid: id,
      // eslint-disable-next-line @typescript-eslint/camelcase
      externalId: isEventInventoryFlow || hasEventInventory ? eventInventoryExternalId : externalId,
      requestedFrom: inventoryDetail ? inventoryDetail.eventType : '',
    };
    setCancelInventory({
      variables: mutation,
    })
      .then(() => {
        setOpenInventoryCancelModal(false);
        refetchEventDetails();
        refetchProcessedEventDetails();
      })
      .catch(error => {
        openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Inventory cancellation error.');
        // eslint-disable-next-line no-console
        console.log(error); // TODO change when some operation needs to be run on error.
      });
  };

  const setUserEnteredComment = (comment: any): void => {
    setUserComment(comment);
    // if (comment) {
    //   const obj = {
    //     comment,
    //     postTime: getUTCDateTime(new Date()),
    //     commentType: 'Customer',
    //     externalId:
    //       isEventInventoryFlow || hasEventInventory ? eventInventoryExternalId : externalId,
    //   };
    //   setUserComment([...(userComment || []), obj]);
    //   createComment({
    //     variables: {
    //       comment,
    //       // eslint-disable-next-line no-nested-ternary
    //       caseExternalId: inventoryDetail.caseExternalId,
    //       // (isEventInventoryFlow || hasEventInventory ? eventInventoryExternalId : externalId),
    //       postTime: getUTCDateTime(new Date()),
    //       commentType: 'Customer',
    //     },
    //   });
    // }
  };

  const setLineItemsPayload = (items: any): void => {
    setLineItems(items);
  };

  const setShipMethodValue = (item: string): void => {
    setShipMethod(item);

    if (inventoryDetail) {
      const payload = {
        caseId: inventoryDetail.caseId,
        caseExternalId: inventoryDetail.caseExternalId,
        accountId: inventoryDetail.accountId,
        branchId: inventoryDetail.branchId,
        salesRepId: inventoryDetail.salesRepId,
        shipToId: inventoryDetail.shipToId,
        serviceLevel: inventoryDetail.serviceLevel,
        // needByDate: inventoryDetail.needByDate,
        shipMethod: item || inventoryDetail.shipMethod || '',
        deliveryContractEmail: inventoryDetail.deliveryContractEmail,
        deliveryContractInstructions: inventoryDetail.deliveryContractInstructions,
        deliveryContractName: inventoryDetail.deliveryContractName,
        deliveryContractPhone: inventoryDetail.deliveryContractPhone,
        dockAvailable: inventoryDetail.dockAvailable,
        isShippingDetails: inventoryDetail.isShippingDetails,
        liftGateNeeded: inventoryDetail.liftGateNeeded,
        shortTruckOnly: inventoryDetail.shortTruckOnly,
        requestedFrom: 'Inventory',
      };
      setLoader(true);
      createUpdateUsage({
        variables: {
          ...payload,
        },
      })
        .then((res: any) => {
          setTimeout(() => {
            refetchData().then((_res: any) => {
              if (res && res.data && res?.data?.createUpdateUsage?.message === 'success') {
                setTimeout(() => {
                  setLoader(false);
                  openSnackBar(NOTIFY_TYPE.SUCCESS, 'Shipping method updated successfully');
                }, 3000);
              }
            });
          });
        })
        .catch(() => {
          setLoader(false);
          openSnackBar(NOTIFY_TYPE.ERROR, 'Something went wrong. Please try again.');
        });
    }
  };

  const formatLineItemToSubmit = (lineItems: any): any => {
    const finalLineItems: Array<any> = [];
    lineItems.map(
      (item: {
        productId: any;
        productNo: any;
        quantity: any;
        usageExternalId: any;
        usageId: any;
        workflowAction: any;
        baseorCapital: any;
      }): any => {
        finalLineItems.push({
          productId: item.productId,
          productNumber: item.productNo,
          quantity: item.quantity,
          usageExternalId: item.usageExternalId,
          usageId: item.usageId,
          workflowAction: item.workflowAction,
          baseorCapital: item?.baseorCapital,
        });
      }
    );
    return finalLineItems;
  };

  const submitInventory = (): void => {
    let isInventoryOnHold: any = false;
    // sunshine act hold alert
    // if (
    //   userInfo?.isSubInventoryOnhold
    //   // userInfo?.onholdReason &&
    //   // userInfo?.onholdReason === 'Regulatory Noncompliance'
    // ) {
    //   openSnackBar(
    //     NOTIFY_TYPE.ERROR,
    //     'Submit is not allowed while user is on hold. Please contact Ops.'
    //   );
    //   return;
    // }
    // if (userInfo?.isinventoryhold) {
    //   openSnackBar(NOTIFY_TYPE.ERROR, 'Inventory  is on hold. Please contact Ops.');
    //   return;
    // }
    // if (userInfo?.isSubInventoryOnhold) {
    //   openSnackBar(NOTIFY_TYPE.ERROR, 'Sub Inventory  is on hold. Please contact Ops.');
    //   openSnackBar(
    //     NOTIFY_TYPE.ERROR,
    //     'Inventory  is currently on hold. Remove this Product/Lot/Serial to advance this order'
    //   );
    //   return;
    // }
    inventoryDetail.lineItems.map((item: any): any => {
      if (item?.productOnHold) {
        isInventoryOnHold = true;
      }
    });
    if (isInventoryOnHold === true) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
      return;
    }

    const mutation = {
      caseId: inventoryDetail.caseId,
      caseExternalId: inventoryDetail.caseExternalId,
      accountId: inventoryDetail.accountId,
      branchId: inventoryDetail.branchId,
      salesRepId: inventoryDetail.salesRepId,
      shipToId: inventoryDetail.shippingId,
      serviceLevel: inventoryDetail.serviceLevel,
      needByDate: inventoryDetail.needByDate,
      shipMethod: inventoryDetail.shipMethod,
      eventType: inventoryDetail.parentEventType,
      comments: userComment,
      lineItems: formatLineItemToSubmit(inventoryDetail.lineItems),
    };
    setSubmitInventory({
      variables: mutation,
    }).catch(error => {
      if (error?.message?.toLowerCase().includes('permission denied')) {
        openSnackBar(
          NOTIFY_TYPE.ERROR,
          'Submit is not allowed while user is on hold. Please contact Ops.'
        );
      } else openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Inventory submission error.');
      // eslint-disable-next-line no-console
      console.log(error); // TODO change when some operation needs to be run on error.
    });
  };

  const formatLineItemToReSubmit = (lineItems: any): any => {
    const finalLineItems: Array<any> = [];
    lineItems.map(
      (item: {
        productId: any;
        productNumber: any;
        quantity: any;
        actualQuantity: any;
        externalId: any;
        lotNumber: any;
        sfid: any;
        unitPrice: any;
        caseUsageExternalId: any;
        priceBookEntryId: any;
        salesUnitOfMeasure: any;
        deliveredDate: any;
        caseUsageId: any;
      }): any => {
        finalLineItems.push({
          caseUsageId: item.caseUsageId,
          caseUsageExternalId: item.caseUsageExternalId,
          priceBookEntryId: item.priceBookEntryId,
          salesUnitOfMeasure: item.salesUnitOfMeasure,
          deliveredDate: item.deliveredDate || null,
          productId: item.productId,
          productNumber: item.productNumber,
          quantity: item.quantity.toString(),
          actualQuantity: item.actualQuantity.toString() || '',
          lotNumber: item.lotNumber || '',
          externalId: item.externalId || null,
          sfid: item.sfid || '',
          unitPrice: item.unitPrice || '',
        });
      }
    );
    return finalLineItems;
  };

  const resubmitInventory = (): void => {
    let isInventoryOnHold: any = false;
    // if (userInfo?.isinventoryhold) {
    //   openSnackBar(NOTIFY_TYPE.ERROR, 'Inventory on hold. Please contact Ops');
    //   return;
    // }
    // if (userInfo?.isSubInventoryOnhold) {
    //   openSnackBar(NOTIFY_TYPE.ERROR, 'Sub Inventory on hold. Please contact Ops');
    //   return;
    // }
    // if (userInfo?.isSubInventoryOnhold) {
    //   openSnackBar(
    //     NOTIFY_TYPE.ERROR,
    //     'Submit is not allowed while user is on hold. Please contact Ops.'
    //   );
    //   return;
    // }
    inventoryDetail.lineItems.map((item: any): any => {
      if (item?.productOnHold === true) {
        isInventoryOnHold = true;
      }
    });
    if (isInventoryOnHold === true) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
      return;
    }

    const mutation = {
      id: inventoryDetail.id,
      caseId: inventoryDetail.caseId,
      caseExternalId: inventoryDetail.caseExternalId,
      externalId: inventoryDetail.externalId,
      shippingId: inventoryDetail.shippingId,
      accountId: inventoryDetail.accountId,
      billingId: inventoryDetail.billingId,
      shipMethod: inventoryDetail.shipMethod?.split('-')?.[0] || '',
      serviceLevel: inventoryDetail.serviceLevel,
      salesRepId: inventoryDetail.salesRepId,
      receivingRepId: inventoryDetail.receivingRepId,
      fromSubInventoryCode: inventoryDetail.fromSubInventoryCode,
      toSubInventoryCode: inventoryDetail.toSubInventoryCode,
      salesOrganization: inventoryDetail.salesOrganization,
      needByDate: inventoryDetail.needByDate,
      comments: userComment,
      requestedFrom: 'InventoryRequest',
      submitType: 'Resubmit',
      lineItems: formatLineItemToReSubmit(inventoryDetail.lineItems),
    };
    reSubmitInventory({
      variables: mutation,
    })
      .then((res: any) => {
        // setLoader(false);
        if (res && res.data && res.data.resubmitOrderDetails?.message === NOTIFY_TYPE.SUCCESS) {
          openSnackBar(NOTIFY_TYPE.SUCCESS, `Inventory Request has been resubmitted successfully.`);
          setTimeout(() => {
            if (reprocessQueue) {
              history.push('/Inventory/FailedRequests');
            } else {
              history.goBack();
            }
          }, 1500);
        }
        // else {
        //   openSnackBar(NOTIFY_TYPE.ERROR, `Failed to submit Inventory Request.`);
        // }
      })
      .catch(error => {
        if (error?.message?.toLowerCase().includes('permission denied')) {
          openSnackBar(
            NOTIFY_TYPE.ERROR,
            'Submit is not allowed while user is on hold. Please contact Ops.'
          );
        } else openSnackBar(NOTIFY_TYPE.ERROR, 'Error: Failed to resubmit Inventory Request..');
        // console.log('We had a trouble saving data to salesforce. Please try again later');
        // eslint-disable-next-line no-console
        console.log(error); // TODO change when some operation needs to be run on error.
      });
  };

  const formLineItems = (lineItems: any): void => {
    const a: any = [];
    lineItems.map((item: any) =>
      a.push({
        part: {
          id: item.productId,
          name: item.productDescription,
        },
        lotNumber: '',
        quantity: item.quantity,
      })
    );
    return a;
  };

  const handleRequestClick = (
    parentEventType: any,
    parentCaseExternalId: any,
    parentEventId: any,
    parentOrderid?: any
  ): void => {
    if (window.location.pathname !== '/surgicalDetails') {
      if (parentEventType?.indexOf('Bill') !== -1) {
        history.push('/OrdersUsageDetails', {
          // eventType: parentEventType,
          orderId: parentOrderid,
          type: 'Processed',
          // id: parentEventId,
        });
      } else {
        history.push('/surgicalDetails', {
          eventType: parentEventType,
          externalId: parentCaseExternalId,
          id: parentEventId,
        });
      }
    }
  };
  const handleInventoryActionDropdownSelect = (option: any): void => {
    if (option && option.value) {
      if (option.value.toLowerCase() === 'cancel_request' && !option.disabled) {
        setOpenInventoryCancelModal(!openInventoryCancelModal);
      } else if (option.value.toLowerCase() === 'submit' && !option.disabled) {
        if (option.disabledReason === 'missing_line_items') {
          openSnackBar(NOTIFY_TYPE.ERROR, 'Please add the parts for the Inventory and submit');
        } else {
          submitInventory();
        }
      } else if (option.value.toLowerCase() === 'resubmit' && !option.disabled) {
        if (option.disabledReason === 'missing_line_items') {
          openSnackBar(NOTIFY_TYPE.ERROR, 'Please add the parts for the Inventory and Resubmit');
        } else {
          resubmitInventory();
        }
      } else if (option.value.toLowerCase() === 'edit' && !option.disabled) {
        setIsEdit(true);
      } else if (option.value.toLowerCase() === 'clone' && !option.disabled) {
        setIsClone(true);
      } else if (option.value.toLowerCase() === 'make_favorite' && !option.disabled) {
        setIsSave(!isSave);
      } else if (option.value.toLowerCase() === 'discrepancy' && !option.disabled) {
        // eslint-disable-next-line no-unused-expressions
        userInfo?.businessRules.forEach((br: any) => {
          if (br.rules === 'Inventory Discrepancy Email Recipient') {
            if (type === 'Processed' || type === 'Failed') {
              window.open(
                `mailto: ${br.values}?subject=Inventory Request: ${inventoryDetail.caseNumber}&body=`
              );
            } else {
              window.open(
                `mailto: ${br.values}?subject=Inventory Request: ${inventoryDetail.eventId}&body=`
              );
            }
          }
        });
      }
    }
  };

  const handleSubmitClick = (): void => {
    if (inventoryDetail && inventoryDetail.lineItems.length === 0) {
      openSnackBar(NOTIFY_TYPE.ERROR, 'Please add the parts for the Inventory and submit');
    } else {
      if (type === 'Failed') {
        resubmitInventory();
      } else {
        submitInventory();
      }
    }
  };

  const handleSaveName = (e: any): void => {
    setFavName(e);
  };

  const handleSaveModalToggle = (): void => {
    setFavName('');
    setIsSave(!isSave);
  };

  const handlelSaveAsFavoriteModalConfirmPress = (): void => {
    setIsSave(!isSave);
    const items: any = [];
    if (inventoryDetail && inventoryDetail.lineItems && inventoryDetail.lineItems.length > 0) {
      inventoryDetail.lineItems.map((el: any) => {
        const obj = {
          part: {
            id: el.productId,
            name: `${el.productNumber ? el.productNumber : el.productNo} ${el.productdescription}`,
          },
          lotNumber: '',
          quantity: el.quantity,
        };
        items.push(obj);
      });
    }
    const finalObject = {
      nameOfFavorite: FavName,
      recordType: 'create',
      userId,
      serviceLevel: inventoryDetail.serviceLevel,
      salesRep: {
        name: inventoryDetail.salesRep,
        id: inventoryDetail.salesRepId,
      },
      externalId: inventoryDetail.caseExternalId,
      caseId: inventoryDetail.caseId,
      account: {
        id: inventoryDetail.accountId,
        name: `${inventoryDetail.accountName}-${inventoryDetail.accountNumber}`,
      },
      shipTo: {
        id: inventoryDetail.shippingId,
        name: `${inventoryDetail.shippingAddessLine1},${
          inventoryDetail.shippingAddessLine2 ? inventoryDetail.shippingAddessLine2 : ''
        },${inventoryDetail.shippingCity},${inventoryDetail.shippingState},${
          inventoryDetail.shippingPostalcode
        }`,
      },
      lineItems: items,
    };
    setInventoryFavorite({
      variables: finalObject,
    }).catch(error => {
      const errMsg = error.message.split(':')[1] || 'Something went wrong. Please try again.';
      openSnackBar(NOTIFY_TYPE.ERROR, errMsg);
      // eslint-disable-next-line no-console
      console.log(error); // TODO change when some operation needs to be run on error.
    });
  };
  const handleModalToggle = useCallback((): void => {
    setIsEdit(!isEdit);
  }, [isEdit]);
  const handleCloneModalToggle = useCallback((): void => {
    setIsClone(!isClone);
  }, [isClone]);

  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };
  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };
  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    if (isEdit) {
      handleModalToggle();
    }
    if (isClone) {
      handleCloneModalToggle();
    }
  };

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleBackClick = () => {
    history.goBack();
  };

  const eventId = inventoryDetail ? inventoryDetail.eventId : '';
  const eventType = inventoryDetail ? inventoryDetail.eventType : '';
  const visualIndicator = inventoryDetail ? inventoryDetail.visualIndicator : '';
  const eventStatus =
    eventInventoryData && eventInventoryData?.getInventoryRequestDetails?.parentEventStatus
      ? eventInventoryData.getInventoryRequestDetails.parentEventStatus
      : '';
  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {loading || loader || submitInventoryLoading || processedLoading ? (
        <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
      ) : (
        <>
          {type === 'Processed' || type === 'Failed' ? (
            <>
              <DetailsHeader
                inventoryReturnDetails={inventoryDetail}
                setShipMethodValue={setShipMethodValue}
                handleEditClick={handleEditClick}
                handleRequestClick={handleRequestClick}
                viewOnly={viewOnly}
                userInfo={userInfo}
                type={type}
                parentEventStatus={eventStatus}
                handleInventoryActionDropdownSelect={handleInventoryActionDropdownSelect}
                irHeaderCofig={inventoryConfig?.headers}
                handleBackClick={handleBackClick}
              />
              {/* <LineItems
                inventoryReturnDetails={inventoryDetail}
                handleLineItemActions={setLineItemsPayload}
                viewOnly
                type={type}
                refetch={refetch}
                // updateLineItems={updateLineItems}
                isEventInventoryFlow={isEventInventoryFlow}
          /> */}
              <InventoryRequestDetailCatalog
                refetch={refetch}
                inventoryDetail={inventoryDetail}
                isEventInventoryFlow={isEventInventoryFlow}
                visualIndicator={visualIndicator}
                type={type}
                hasEventInventory={hasEventInventory}
                eventInventoryExternalId={eventInventoryExternalId}
                externalId={externalId}
                changeSelectedTab={changeSelectedTab}
                tabConfig={inventoryConfig}
                refetchOnAdd={refetchOnAdd}
                viewOnly={viewOnly}
              />
              {selectedTabIndex !== 1 && (
                <Comments
                  setUserEnteredComment={setUserEnteredComment}
                  inventoryReturnDetails={inventoryDetail}
                  // setUserEnteredComment={setUserEnteredComment}
                  viewOnly={false}
                  type={type}
                />
              )}
              {/* {/* <div className="slds-m-around_small">
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      className="yellow-btn"
                      align="right"
                      label="Submit"
                      // disabled={type === 'Processed'}
                      disabled={
                        type === 'Processed' ||
                        (type === 'Failed' &&
                          !(
                            userInfo &&
                            PERSONA_CONDITION.some(el =>
                              userInfo.personas.toLowerCase().includes(el)
                            )
                          ))
                      }
                      onClick={handleSubmitClick}
                    />
                  </Grid>
                </Grid>
              </div> */}
            </>
          ) : (
            <>
              <InventoryRequestDetailHeader
                inventoryDetail={inventoryDetail}
                isEventInventoryFlow={isEventInventoryFlow}
                handleInventoryActionDropdownSelect={handleInventoryActionDropdownSelect}
                handleRequestClick={handleRequestClick}
                hasEventInventory={hasEventInventory}
                type={type}
                parentEventStatus={eventStatus}
                irHeaderCofig={inventoryConfig?.headers}
              />
              <InventoryRequestDetailCatalog
                refetch={refetch}
                inventoryDetail={inventoryDetail}
                isEventInventoryFlow={isEventInventoryFlow}
                visualIndicator={visualIndicator}
                parentEventStatus={eventStatus}
                type={type}
                hasEventInventory={hasEventInventory}
                eventInventoryExternalId={eventInventoryExternalId}
                externalId={externalId}
                changeSelectedTab={changeSelectedTab}
                tabConfig={inventoryConfig}
              />
              <Comments
                inventoryReturnDetails={inventoryDetail}
                setUserEnteredComment={setUserEnteredComment}
                viewOnly={false}
                type=""
              />
              {openInventoryCancelModal && (
                <CancelInventory
                  cancelInventoryModalVisible={openInventoryCancelModal}
                  handleCancelInventoryModal={handleCancelInventoryModal}
                  eventId={eventId}
                  eventType={eventType}
                  cancelInventory={cancelInventory}
                  cancelInventoryLoading={cancelInventoryLoading}
                />
              )}
              {canceModalVisible && (
                <CancelEventModal
                  isInventory
                  canceModalVisible={canceModalVisible}
                  handleCancelModal={handleCancelModal}
                  handleCancelModalConfirmPress={handleCancelModalConfirmPress}
                  edit={isEdit}
                />
              )}
            </>
          )}
        </>
      )}
      {isClone && (
        <InventoryRequest
          isClone={isClone}
          isOpen={isClone}
          inventoryDetail={inventoryDetail}
          toggleOpen={handleCloneModalToggle}
          formData={formdata}
          refetch={refetch}
          eventType={'Request Inventory' || eventType}
          handleCancelButton={handleCloneModalToggle}
        />
      )}
      {canceModalVisible && (
        <CancelEventModal
          isInventory={isClone || true}
          canceModalVisible={canceModalVisible}
          handleCancelModal={handleCancelModal}
          handleCancelModalConfirmPress={handleCancelModalConfirmPress}
          edit={isEdit}
        />
      )}
      {isSave && (
        <InventoryFavoriteSaveModal
          favName={FavName}
          handleSaveName={handleSaveName}
          showEditConfirmationModal={isSave}
          handleModalToggle={handleSaveModalToggle}
          handlelSaveFavoriteModalConfirmPress={handlelSaveAsFavoriteModalConfirmPress}
        />
      )}
      {isEdit && (
        <InventoryRequest
          isOpen={isEdit}
          inventoryDetail={inventoryDetail}
          toggleOpen={handleModalToggle}
          formData={formdata}
          type={type}
          refetch={refetch}
          eventType={'Request Inventory' || eventType}
          handleCancelButton={handleCancelButton}
        />
      )}
    </IconSettings>
  );
};

export default withRouter(InventoryRequestDetail);
